@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap");

body {
  margin: 0;
  font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slick-slide > div {
  margin: 10px 10px;
}

.slick-arrow {
  min-width: 30px;
  min-height: 30px;
}

.hover-red:hover {
  transition-duration: 0.4s;
  box-shadow: 8px 6px #ff3131;
}

.hover-dark:hover {
  transition-duration: 0.4s;
  box-shadow: 8px 6px #404040;
}
